<!-- This component is for handling vue-editors via deltas from the swandoola database instead of html -->
<!-- Todo: move full functionality vue2-editors to use this component instead -->

<template>
  <div>
    <vue-editor
      :ref="'DeltaEditor' + item.id"
      class="bg-white"
      :editor-toolbar="toolbar"
      :editorOptions="editorSettings"
      rows="3"
      @text-change="update()"
      useCustomImageHandler @image-added="handleImageAdded"
    ></vue-editor>
  </div>
</template>

<script>
import VueEditor from "vue2-editor";
import Quill from "quill";
import ImageResize from "quill-image-resize-module--fix-imports-error";

Quill.register("modules/imageResize", ImageResize);

export default {
  props: ["item", "toolbar"],
  data: function () {
    return {
      editorSettings: {
        modules: { 
        imageResize: { 
          displaySize: true 
          }
        }
      },
    };
  },
  mounted: function () {
      this.$nextTick(() => {
        if(this.item.delta && this.item.delta.length > 0) {
            this.$refs['DeltaEditor' + this.item.id].quill.setContents(JSON.parse(this.item.delta));
        }
      });
  },
  methods: {
      updateDelta() {
          this.item.delta = JSON.stringify(this.$refs['DeltaEditor' + this.item.id].quill.getContents());
      },
      updateContent() {
          this.item.content = this.$refs['DeltaEditor' + this.item.id].quill.getText();
      },
      update() {
          this.updateDelta();
          this.updateContent();
      },
      handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      
      var formData = new FormData();
      formData.append("file", file);

      this.$axios({
        url:  
        process.env.VUE_APP_API_URL +
          "/documents/0/upload-image",
        method: "POST",
        data: formData
      })
        .then(result => {
          console.log(result.data);
          let url = result.data.url;
          Editor.insertEmbed(cursorLocation, "image", url);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>