<template>
  <div class="row">
    <div class="col-md-12">
      <busy :visible="busy" />
      <p class="alert alert-primary mb-3">
        <i class="far fa-info me-2"></i>From here you can manage your
        phraseology categories and organise them as you need.
      </p>
      <form @submit.prevent="addNewCategory" class="form-inline">
        <div class="row mb-3 w-75">
          <div class="col">
            <input
              v-model="newCategory"
              :placeholder="'Add a new phraseology category to your library'"
              type="text"
              class="form-control w-100"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-end ms-2">
            <button class="btn btn-primary" type="submit">
              <i class="far fa-check me-2"></i>
              Save Category
            </button>
          </div>
        </div>
      </form>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="c in filteredCategories" :key="c.id">
          <div class="row">
            <div class="col my-auto border-right">
              <div v-if="editCategory !== c" v-html="c.name"></div>
              <div v-else>
                <input v-model="c.name" type="text" class="form-control" />
              </div>
            </div>
            <div class="col-auto my-auto ms-auto">
              <div v-if="editCategory !== c">
                <button
                  class="btn btn-light btn-sm me-2"
                  @click="editCategory = c"
                >
                  <i class="far fa-pencil me-2"></i>Edit
                </button>
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="deleteCategory(c)"
                >
                  <i class="far fa-trash me-2"></i>Delete
                </button>
              </div>
              <div v-else>
                <button
                  class="btn btn-light btn-sm me-2"
                  @click="editCategory = null"
                >
                  <i class="far fa-times me-2"></i>Cancel
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  @click="updateCategory(c)"
                >
                  <i class="far fa-save me-2"></i>Save
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["searchText"],
  data() {
    return {
      categories: [],
      busy: false,
      editCategory: null,
      newCategory: null,
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "link"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["clean"],
      ],
    };
  },
  computed: {
    filteredCategories() {
      if(!this.searchText || this.searchText.length < 1) {
        return this.categories;
      }
      return this.categories.filter((category) => {
        return category.name.toLowerCase().includes(this.searchText.toLowerCase());
      });
    }
  },
  methods: {
    getAutoSuggestionCategories() {
      this.busy = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/documents/dtas-categories")
        .then(({ data }) => {
          this.categories = data;
          this.busy = false;
        });
    },
    addNewCategory() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/documents/dtas-categories", {
          name: this.newCategory,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.getAutoSuggestionCategories();
          this.busy = false;
        });
    },
    deleteCategory(category) {
      if (
        confirm(
          "Are you sure you wish to delete this category, this action cannot be undone!?"
        )
      ) {
        this.busy = true;
        this.$axios.delete(process.env.VUE_APP_API_URL + "/documents/dtas-categories/" + category.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.getAutoSuggestionCategories();
            this.busy = false;
          });
      }
    },
    updateCategory(category) {
      this.busy = true;
      this.$axios.put(process.env.VUE_APP_API_URL + "/documents/dtas-categories/" + category.id, {
          name: category.name,
        })
        .then(({ data }) => {
          this.getAutoSuggestionCategories();
          this.$EventBus.$emit("alert", data);
          this.busy = false;
        });
    },
  },
  mounted() {
    this.getAutoSuggestionCategories();
  },
};
</script>