<template>
    <div>
      <busy :visible="busy" />
      <!-- Card -->
      <p class="alert alert-primary mb-3 mt-0">
        <i class="far fa-info me-2"></i>Add a new item to your phraseology to be used in text or list items.
      </p>

      <form @submit.prevent="addNewPhrase">
        <div class="custom-control custom-switch mb-4">
          <input
            type="checkbox"
            v-model="listMode"
            class="custom-control-input"
            id="listModeSwitch"
          />
          <label class="custom-control-label" for="listModeSwitch">{{
            listMode
              ? "You are in list mode - switch to phrase mode"
              : "You are in phrase mode - switch to list item mode"
          }}</label>
        </div>

        <div class="row mb-3">
          <div class="col">
            <VueEditorDelta
              v-if="!listMode"
              :item="item"
              :toolbar="customToolbar"
            ></VueEditorDelta>
            <input
              v-if="listMode"
              v-model="newPhrase"
              :placeholder="'Add a new list item to your library'"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="row mb-3" v-if="!listMode">
          <div class="col form-inline">
            <label class="me-3">Category:</label>
            <select
              class="form-control form-control-sm w-25"
              style="right: 0px"
              v-model="selectedCat"
            >
              <option v-for="c in categories" :key="c.id" :value="c.id">
                {{ c.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-end">
            <button class="btn btn-primary" type="submit">
              <i class="far fa-check me-2"></i>
              Save {{ listMode ? "List Item" : "Phrase" }}
            </button>
          </div>
        </div>
      </form>
    </div>
</template>

<script>
import VueEditorDelta from '../../../../components/editor/Vue-Editor-Ex';

export default {
  data() {
    return {
      categories: [],
      busy: false,
      selectedCat: 1,
      listMode: false,
      newPhrase: "",
      item: [
        { id: 1 },
        { content: "" },
        { delta: "" },
      ],
      customToolbar: [
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [ 'link', 'image' ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["clean"],
      ],
    };
  },
  methods: {
    getAutoSuggestionCategories() {
      this.busy = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/documents/dtas-categories")
        .then(({ data }) => {
          this.categories = data;
          this.busy = false;
        });
    },
    addNewPhrase() {
      this.busy = true;
      this.$axios.post(process.env.VUE_APP_API_URL + "/phraseology", {
          content: this.listMode ? this.newPhrase : this.item.content,
          delta: this.listMode ? null : this.item.delta,
          type: this.listMode ? "list" : "text",
          category_id: this.selectedCat,
        })
        .then(({ data }) => {
          this.newPhrase = "";
          this.$EventBus.$emit("alert", data);
          this.busy = false;
        });
    },
  },
  mounted() {
    this.getAutoSuggestionCategories();
  },
  components: {
    VueEditorDelta
  }
};
</script>