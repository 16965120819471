<template>
  <div class="card-body pt-0">
    <div class="row">
      <div class="col">
        <busy :visible="busy" />
        <ul class="list-group list-group-flush">
          <li class="list-group-item" v-for="p in filteredPhrases" :key="p.id">
            <div class="row">
              <div class="col my-auto border-right">
                <div class="row">
                  <div class="cat-info col-md-3 mb-2">
                    <i v-if="editPhrase !== p">Category: {{ getCategoryNamefromID(p.category_id) }}</i>
                    <span class="form-inline" v-else>
                      <label>Category: </label>
                      <select
                    class="form-control form-control-sm cat-select ms-2"
                    @change="handleChange"
                  >
                    <option
                      v-for="category in categories"
                      :key="category.name"
                      :value="category.id"
                      :selected="p.category_id == category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                    </span>
                  </div>
                  <div
                    class="col"
                    v-if="editPhrase !== p"
                    :inner-html.prop="p.content | truncate(300, '...')"
                  ></div>
                  <div v-else>
                    <!-- Keep this for handling older html items -->
                    <vue-editor
                      v-if="p.type === 'text' && !p.delta"
                      v-model="p.content"
                      class="bg-white"
                      :editor-toolbar="customToolbar"
                      rows="3"
                    ></vue-editor>
                    <!-- ------------------------------------------->
                    <VueEditorDelta
                      v-if="p.type === 'text' && p.delta"
                      :item="p"
                      :toolbar="customToolbar"
                    ></VueEditorDelta>
                    <!-- vue-editor
                      :ref="'DeltaEditor' + p.id"
                      v-if="p.type === 'text' && p.delta"
                      class="bg-white"
                      :editor-toolbar="customToolbar"
                      rows="3"
                    ></vue-editor-->
                    <input
                      v-if="p.type === 'list'"
                      v-model="p.content"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto my-auto ms-auto">
                <div v-if="editPhrase !== p">
                  <button
                    class="btn btn-light btn-sm me-2"
                    @click="editPhrase = p"
                  >
                    <i class="far fa-pencil me-2"></i>Edit
                  </button>
                  <button
                    class="btn btn-outline-danger btn-sm"
                    @click="deletePhrase(p)"
                  >
                    <i class="far fa-trash me-2"></i>Delete
                  </button>
                </div>
                <div v-else>
                  <button
                    class="btn btn-light btn-sm me-2"
                    @click="editPhrase = null"
                  >
                    <i class="far fa-times me-2"></i>Cancel
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="updatePhrase(p)"
                  >
                    <i class="far fa-save me-2"></i>Save
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import VueEditorDelta from '../../../../components/editor/Vue-Editor-Ex';

export default {
  props: ["searchText"],
  data() {
    return {
      busy: false,
      phrases: [],
      newPhrase: "",
      categories: [],
      editPhrase: null,
      customToolbar: [
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [ 'link', 'image' ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["clean"],
      ],
    };
  },
  computed: {
    filteredPhrases() {
      if (!this.searchText || this.searchText.length < 1) {
        return this.phrases;
      }
      return this.phrases.filter((phrase) => {
        return phrase.content.toLowerCase().includes(this.searchText.toLowerCase());
      });
    }
  },
  methods: {
    handleChange(e) {
      if (e.target.options.selectedIndex > -1) {
        this.selectedCat =
          e.target.options[e.target.options.selectedIndex].value;
        console.log(this.selectedCat);
      }
    },
    getCategoryNamefromID(id) {
      var targetCategory = null;
      this.categories.find((c) => {
        if (c.id === id) {
          targetCategory = c;
          return true;
        }
      });
      if (targetCategory != null) {
        return targetCategory.name;
      } else return "Uncategorised";
    },
    getAutoSuggestionCategories() {
      this.busy = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/documents/dtas-categories")
        .then(({ data }) => {
          this.categories = data;
          this.busy = false;
        });
    },
    fetchPhrases() {
      this.busy = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/phraseology")
        .then(({ data }) => {
          this.phrases = data;
          this.busy = false;
        });
    },
    addNewPhrase() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/phraseology", {
          content: this.newPhrase,
          type: this.listMode ? "list" : "text",
          category_id: this.selectedCat,
        })
        .then(({ data }) => {
          this.newPhrase = "";
          this.fetchPhrases();
          this.$EventBus.$emit("alert", data);
          this.busy = false;
        });
    },
    deletePhrase(phrase) {
      if (
        confirm(
          "Are you sure you wish to delete this phrase, this action cannot be undone!?"
        )
      ) {
        this.busy = true;
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/phraseology/" + phrase.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchPhrases();
            this.busy = false;
          });
      }
    },
    updatePhrase(phrase) {
      this.busy = true;
      this.$axios
        .put(process.env.VUE_APP_API_URL + "/phraseology/" + phrase.id, {
          content: phrase.content,
          delta: phrase.delta,
          category_id: this.selectedCat,
        })
        .then(({ data }) => {
          this.fetchPhrases();
          this.$EventBus.$emit("alert", data);
          this.busy = false;
        });
    },
  },
  mounted() {
    this.fetchPhrases();
    this.getAutoSuggestionCategories();
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    },
  },
  components: {
    VueEditorDelta,
  }
};
</script>