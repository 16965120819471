<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h4 class="fw-bold text-primary">Phraseology</h4>
        <ul class="nav nav-tabs mb-3">
          <li class="nav-item me-2" @click="handlePage(0)">
            <a href="#phraseology" :class="viewPage == 0 ? 'nav-link active' : 'nav-link'">Phraseology</a>
          </li>
          <li class="nav-item me-2" @click="handlePage(1)">
            <a href="#categories" :class="viewPage == 1 ? 'nav-link active' : 'nav-link'">Categories</a>
          </li>
          <li class="nav-item me-2" @click="handlePage(2)">
            <a href="#create" :class="viewPage == 2 ? 'nav-link active' : 'nav-link'">Create New</a>
          </li>
        </ul>
        <div v-if="viewPage !== 2">
          <input
            type="text"
            class="form-control w-50"
            placeholder="Search phraseology"
            v-model="searchText"
          />
        </div>
        <PhraseLibrary v-if="viewPage == 0" :searchText="searchText"></PhraseLibrary>
        <PhraseCategories v-if="viewPage == 1" :searchText="searchText"></PhraseCategories>
        <PhraseCreate v-if="viewPage == 2" :searchText="searchText"></PhraseCreate>
      </div>
    </div>
  </div>
</template>

<script>

import PhraseCategories from "./phraseology-partials/categories";
import PhraseCreate from "./phraseology-partials/create";
import PhraseLibrary from "./phraseology-partials/library";

export default {
  props: [],
  data() {
    return {
      viewPage: 0,
      searchText: '',
    };
  },
  methods: {
    handlePage(index) {
      this.viewPage = index;
      this.searchText = ''
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    },
  },
  components: {

    PhraseCategories,
    PhraseCreate,
    PhraseLibrary,
  },
};
</script>